'use strict'

import errorService from '@/services/errorService'
import _ from 'lodash'
// const service = process.env.VUE_APP_service_configurazioni || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)

var config, service, operatori
(async () => {
  if (!config || !service) {
    await getConfigFile()
  }
  if (!operatori) {
    operatori = await getOperatori() // necessario per getOperatoriSynk
  }
  /*   const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_configurazioni || process.env.VUE_APP_service_configurazioni */
})()

async function getConfigFile() {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_configurazioni || process.env.VUE_APP_service_configurazioni
}

async function cicleSync() {
  try {
    const response = await fetch(`${service}/configurazioni/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/configurazioni/mode`)
    return await response.json()
  } catch {
    return {
      modulo: 'CONFIGURAZIONI',
      locale: '---',
      remote: '---',
      versione: '---',
      health: '---'
    }
  }
}

function getDisplayVersion() {
  return config.swVersion
}

function getPostazione() {
  // TODO: Prevedere su bo-licenze la gestione delle postazioni (tab su clienti)
  // Leggere da Hardware il seriale della scheda del pc o codice analogo --> scarica configurazione giusta !!
  return config.postazione || 'cassa01'
}

async function searchConfigurazioni(searchText) {
  const response = await fetch(`${service}/configurazioni/search?flt=${searchText}`)
  return await response.json()
}

async function getInfoBarcode(searchText) {
  const response = await fetch(`${service}/configurazioni/barcode?flt=${searchText}`)
  return await response.json()
}

async function getElencoConfigurazioni() {
  const response = await fetch(`${service}/configurazioni?keys=true`)
  const result = await response.json()
  return result.rows
}

async function getConfigurazione(configurazioneId, subConf) {
  try {
    if (!config || !service) {
      await getConfigFile()
    }
    const response = await fetch(`${service}/configurazioni/${configurazioneId}${subConf ? '/' + subConf : ''}`)
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'getConfigurazione')
  }
}

async function creaConfigurazione(configurazione) {
  const response = await fetch(`${service}/configurazioni/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(configurazione)
  })
  return await response.json()
}

async function modificaConfigurazione(configurazione) {
  const response = await fetch(`${service}/configurazioni/${configurazione._id}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(configurazione)
  })
  return await response.json()
}

async function getBarcodeInfo(tipo) {
  const response = await fetch(`${service}/configurazioni/barcode/tipo/${tipo}`)
  return await response.json()
}

async function getBarcodeInfoMultiplo(tipo) {
  const response = await fetch(`${service}/configurazioni/barcode/tipo/multiplo/${tipo}`)
  return await response.json()
}

async function setBarcodeInfo(tipo, operazione, counter) {
  var data = {
    operazione,
    counter
  }
  const response = await fetch(`${service}/configurazioni/barcode/tipo/${tipo}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function getOperatori(tipo) {
  const response = await fetch(`${service}/configurazioni/operatori`)
  const result = await response.json()
  return tipo && result ? result.filter(x => x[tipo]) : result || []
}

function getOperatoriSynk(tipo) {
  return tipo && operatori ? operatori.filter(x => x[tipo]) : operatori || []
}

async function getOperatore(pin) {
  const response = await fetch(`${service}/configurazioni/operatori/${pin}`)
  return await response.json()
}

async function aggiornaCategorie(configurazioneId, subConf, categorie) {
  try {
    const categorieEsistenti = await getConfigurazione(configurazioneId, subConf)
    if (categorie.length === 0) return categorieEsistenti
    if (categorie[0].codice) {
      categorie = _.unionBy(categorie, categorieEsistenti, 'codice')
    } else {
      categorie = _.union(categorie, categorieEsistenti)
    }
    const configurazione = { _id: configurazioneId }
    configurazione[subConf] = categorie
    await modificaConfigurazione(configurazione)
    return categorie
  } catch (error) {
    console.log(`aggiornaCategorie errore: ${error}`)
    return null
  }
}

async function shutdownPC() {
  const response = await fetch(`${service}/configurazioni/shutdown`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function salvaGruppoTasti(layoutTasti, zona, tastiLocali) {
  const gruppoTasti = await getConfigurazione('gruppi_tasti')
  const gruppi = gruppoTasti[layoutTasti].gruppi
  const gruppo = gruppi.find(x => x.zona === zona)
  gruppo.tasti = tastiLocali.filter(x => x.status !== 'deleted')
  await modificaConfigurazione(gruppoTasti)
}

async function getCurrentLayout() {
  // possibilità di utilizzare più template con lo stesso layout ... da implementare utilizzando il campo "template"
  const conf = await getConfigurazione('display_generale', 'cassa')
  if (conf && conf.layout) {
    return {
      base: conf.layout.base,
      template: conf.layout.template
    }
    // return (conf.layout && conf.layout.base) || 'layout05'
  } else {
    return {
      base: 'layout05',
      template: ''
    }
    // return 'layout05'
  }
}

async function getNumeratore(tipo, incrementa, annoRiferimento) {
  const response = await fetch(`${service}/configurazioni/documenti/numeratore/${tipo}?inc=${incrementa}${annoRiferimento ? '&anno=' + annoRiferimento : ''}`)
  return await response.json()
}

async function getNumeratoreClienti(ragioneSociale) {
  var data = {
    ragioneSociale
  }
  const response = await fetch(`${service}/configurazioni/clienti/numeratore`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function getMemoriaCondivisa(chiave) {
  const response = await fetch(`${service}/configurazioni/memoria/${chiave}`)
  return response.status === 200 ? await response.json() : null
}

async function takeMemoriaCondivisa(chiave) {
  const response = await fetch(`${service}/configurazioni/memoria/take/${chiave}`)
  return response.status === 200 ? await response.json() : null
}

async function setMemoriaCondivisa(chiave, valore) {
  var data = {
    valore
  }
  const response = await fetch(`${service}/configurazioni/memoria/${chiave}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

export default {
  cicleSync,
  getRunMode,
  getDisplayVersion,
  getPostazione,
  getElencoConfigurazioni,
  searchConfigurazioni,
  getInfoBarcode,
  getConfigurazione,
  creaConfigurazione,
  modificaConfigurazione,
  getBarcodeInfo,
  getBarcodeInfoMultiplo,
  setBarcodeInfo,
  getOperatori,
  getOperatoriSynk,
  getOperatore,
  aggiornaCategorie,
  shutdownPC,
  salvaGruppoTasti,
  getCurrentLayout,
  getNumeratore,
  getNumeratoreClienti,
  getMemoriaCondivisa,
  takeMemoriaCondivisa,
  setMemoriaCondivisa
}
