import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    showJsonEditor: false,
    jsonContent: '',
    inModifica: '',
    salvataggioRichiesto: false,
    readOnly: false
  },
  getters: {
    showJsonEditor: state => state.showJsonEditor,
    jsonContent: state => state.jsonContent,
    inModifica: state => state.inModifica,
    salvataggioRichiesto: state => state.salvataggioRichiesto,
    readOnly: state => state.readOnly
  },
  mutations: {
    SHOW_JSON_EDITOR (state) {
      state.showJsonEditor = true
    },
    HIDE_JSON_EDITOR (state) {
      state.showJsonEditor = false
    },
    SET_JSON_EDITOR (state, payload) {
      state.jsonContent = payload
    },
    CLEAR_JSON_EDITOR (state) {
      state.jsonContent = ''
    },
    SET_IN_MODIFICA (state, payload) {
      state.inModifica = payload
    },
    CLEAR_IN_MODIFICA (state) {
      state.inModifica = ''
    },
    SET_SALVATAGGIO_RICHIESTO (state, payload) {
      state.salvataggioRichiesto = payload
    },
    CLEAR_SALVATAGGIO_RICHIESTO (state) {
      state.salvataggioRichiesto = ''
    },
    SET_READ_ONLY (state, payload) {
      state.readOnly = payload
    }
  },
  actions: {
  }
}
