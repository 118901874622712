import Vue from 'vue'
import Vuex from 'vuex'
import sharedMutations from 'vuex-shared-mutations'

import movimenti from './movimenti'
import prodotti from './prodotti'
import appuntamenti from './appuntamenti'
import clienti from './clienti'
import settings from './settings'
import scontrino from './scontrino'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    movimenti,
    prodotti,
    appuntamenti,
    clienti,
    settings,
    scontrino
  },
  state: {
    popupKeyboardInput: '',
    activeTab: '',
    externalTab: '',
    internalTab: '',
    showPopupKeyboard: true,
    enterKeyTitlePopupKeyboard: 'NASCONDI',
    keyPressPopupKeyboard: '',
    keyPressCounterPopupKeyboard: 0,
    keyboardHeight: 0,
    showPopupListResult: true,
    listResultSource: [],
    listResultItemIcon: '',
    listResultItemClasses: {},
    listResult: null,
    initialPrinterState: false,
    serviceStatus: [],
    layoutTasti: ''
  },
  getters: {
    popupKeyboardInput(state) {
      return { parent: state.externalTab, tab: state.internalTab, key: state.popupKeyboardInput }
    },
    activeTab: state => state.activeTab,
    externalTab: state => state.externalTab,
    internalTab: state => state.internalTab,
    showPopupKeyboard: state => state.showPopupKeyboard,
    enterKeyTitlePopupKeyboard: state => state.enterKeyTitlePopupKeyboard,
    keyPressPopupKeyboard (state) {
      return { parent: state.externalTab, tab: state.internalTab, key: state.keyPressPopupKeyboard, count: state.keyPressCounterPopupKeyboard }
    },
    keyboardHeight: state => state.keyboardHeight,
    showPopupListResult: state => state.showPopupListResult,
    listResultSource: state => state.listResultSource,
    listResultItemIcon: state => state.listResultItemIcon,
    listResultItemClasses: state => state.listResultItemClasses,
    listResult (state) {
      return { parent: state.externalTab, tab: state.internalTab, result: state.listResult }
    },
    initialPrinterState: state => state.initialPrinterState,
    serviceStatus: state => state.serviceStatus,
    layoutTasti: state => state.layoutTasti
  },
  mutations: {
    SET_POPUP_KEYBOARD_INPUT (state, payload) {
      console.log('SET_POPUP_KEYBOARD_INPUT')
      state.popupKeyboardInput = payload
    },
    CLEAR_POPUP_KEYBOARD_INPUT (state) {
      console.log('CLEAR_POPUP_KEYBOARD_INPUT')
      state.popupKeyboardInput = ''
      state.keyPressCounterPopupKeyboard = 0
    },
    ADD_INPUT_POPUP_KEYBOARD (state, payload) {
      switch (payload) {
        case '{bksp}':
        case '{backspace}':
        case '{delete}':
          state.popupKeyboardInput = state.popupKeyboardInput.slice(0, -1)
          break
        case '{esci}':
        case '{control}':
          break
        case '{space}':
          state.popupKeyboardInput += ' '
          break
        default: // TODO: evitare tasti speciali ...
          console.log('payload kb', payload)
          if (payload.indexOf('{') === -1) {
            state.popupKeyboardInput += payload
          }
          break
      }
    },
    SET_ACTIVE_TAB (state, payload) {
      console.log('set active tab', payload)
      state.activeTab = payload
    },
    SET_EXTERNAL_TAB(state, payload) {
      // console.log('set external tab', payload)
      state.popupKeyboardInput = ''
      state.keyPressCounterPopupKeyboard = 0
      state.listResult = null
      state.externalTab = payload
    },
    SET_INTERNAL_TAB (state, payload) {
      // console.log('set internal tab', payload)
      state.popupKeyboardInput = ''
      state.keyPressCounterPopupKeyboard = 0
      state.listResult = null
      state.internalTab = payload
    },
    SHOW_POPUP_KEYBOARD (state) {
      state.showPopupKeyboard = true
      state.keyPressCounterPopupKeyboard = 0
    },
    HIDE_POPUP_KEYBOARD (state) {
      /*       state.showPopupKeyboard = false
      state.keyPressCounterPopupKeyboard = 0 */
    },
    TOGGLE_POPUP_KEYBOARD (state) {
    /*       state.showPopupKeyboard = !state.showPopupKeyboard
      state.keyPressCounterPopupKeyboard = 0 */
    },
    SET_ENTER_KEY_TITLE_POPUP_KEYBOARD (state, payload) {
      state.enterKeyTitlePopupKeyboard = payload
    },
    SET_KEY_PRESS_POPUP_KEYBOARD (state, payload) {
      state.keyPressPopupKeyboard = payload
      state.keyPressCounterPopupKeyboard++
      // console.log('press', payload)
    },
    CLEAR_KEY_PRESS_POPUP_KEYBOARD(state) {
      state.keyPressPopupKeyboard = ''
      state.keyPressCounterPopupKeyboard = 0
    },
    SET_POPUP_KEYBOARD_HEIGHT (state, payload) {
      console.log('SET_POPUP_KEYBOARD_HEIGHT', payload)
      state.keyboardHeight = payload
    },
    SHOW_POPUP_LIST_RESULT (state) {
      state.showPopupListResult = true
    },
    HIDE_POPUP_LIST_RESULT  (state) {
      state.showPopupListResult = false
    },
    TOGGLE_POPUP_LIST_RESULT  (state) {
      state.showPopupListResult = !state.showPopupListResult
    },
    SET_SOURCE_POPUP_LIST_RESULT (state, payload) {
      state.listResultSource = payload.list
      state.listResultItemIcon = payload.icon
      state.listResultItemClasses = payload.classes
    },
    CLEAR_SOURCE_POPUP_LIST_RESULT (state) {
      console.log('CLEAR_SOURCE_POPUP_LIST_RESULT')
      state.listResultSource = []
    },
    SET_POPUP_LIST_RESULT_ICON (state, payload) {
      state.listResultItemIcon = payload
    },
    SET_POPUP_LIST_RESULT_CLASSES (state, payload) {
      state.listResultItemClasses = payload
    },
    SET_POPUP_LIST_RESULT (state, payload) {
      state.listResult = payload
    },
    CLEAR_POPUP_LIST_RESULT (state) {
      state.listResult = null
    },
    SET_INITIAL_PRINTER_STATUS_FLAG (state) {
      state.initialPrinterState = true
    },
    SET_SERVICE_STATUS(state, status) {
      state.serviceStatus = [...status]
    },
    SET_LAYOUT_TASTI(state, layout) {
      state.layoutTasti = layout
      console.log('state layout -->', layout)
    }
  },
  actions: {
  },
  plugins: [sharedMutations({ predicate: ['scontrino/SET_CARRELLO', 'scontrino/CLEAR_CARRELLO', 'scontrino/ADD_RIGA'] })]
})
