import Vue from 'vue'
import VueRouter from 'vue-router'
import configurazioni from '@/services/configurazioniService'
import store from '@/store'

Vue.use(VueRouter)

var layoutCorrente
var template

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // Nel caso debba entrare subito su layout
    component: async () => {
      if (!layoutCorrente) {
        const infoLayout = await configurazioni.getCurrentLayout()
        layoutCorrente = infoLayout.base
        template = infoLayout.template
        if (template && !template.startsWith(layoutCorrente)) {
          template = `${layoutCorrente}_${template}`
        }
      }

      // per uniformare Layout04 con Layout_04 (uno su impostazioni, l'altro su gruppi_tasti, basta cambiare il valore su impostazioni e sostituire i case sotto)
      switch (layoutCorrente) {
        case 'layout01':
        case 'layout_01':
          store.commit('SET_LAYOUT_TASTI', template || 'layout_01') // c'è underscore prima di 01 ...
          return import('../views/Layout_01/Layout.vue')
        case 'layout04':
        case 'layout_04':
          // Apro sempre la view Layout_04 ma potrei passare layoutTasti = layout_04_ottici, layout_04_abbigliamento, etc.
          store.commit('SET_LAYOUT_TASTI', template || 'layout_04') // c'è underscore prima di 04 ...
          return import('../views/Layout_04/Layout.vue')
        case 'layout05':
        case 'layout_05':
          store.commit('SET_LAYOUT_TASTI', template || 'layout_05') // c'è underscore prima di 05 ...
          return import('../views/Layout_05/Layout.vue')
        case 'layout06':
        case 'layout_06':
          store.commit('SET_LAYOUT_TASTI', template || 'layout_06') // c'è underscore prima di 06 ...
          return import('../views/Layout_06/Layout.vue')
        default:
          return import('../views/Layout_05/Layout.vue')
      }
    }
  },
  {
    path: '/1',
    name: 'Layout01',
    // component: () => import(/* webpackChunkName: "layout04" */ '../views/Layout_04/Layout.vue')
    component: () => {
      store.commit('SET_LAYOUT_TASTI', 'layout_01')
      return import(/* webpackChunkName: "layout01" */ '../views/Layout_01/Layout.vue')
    }
  },
  {
    path: '/4/:template?',
    name: 'Layout04',
    // component: () => import(/* webpackChunkName: "layout04" */ '../views/Layout_04/Layout.vue')
    component: () => {
      store.commit('SET_LAYOUT_TASTI', 'layout_04')
      return import(/* webpackChunkName: "layout04" */ '../views/Layout_04/Layout.vue')
    },
    props: route => ({ template: route.params.template })
  },
  {
    path: '/5/:template?',
    name: 'Layout05',
    // component: () => import(/* webpackChunkName: "layout05" */ '../views/Layout_05/Layout.vue')
    component: () => {
      store.commit('SET_LAYOUT_TASTI', 'layout_05')
      return import(/* webpackChunkName: "layout05" */ '../views/Layout_05/Layout.vue')
    },
    props: route => ({ template: route.params.template })
  },
  {
    path: '/6/:template?',
    name: 'Layout06',
    // component: () => import(/* webpackChunkName: "layout06" */ '../views/Layout_06/Layout.vue')
    component: () => {
      store.commit('SET_LAYOUT_TASTI', 'layout_06')
      return import(/* webpackChunkName: "layout06" */ '../views/Layout_06/Layout.vue')
    },
    props: route => ({ template: route.params.template })
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/import',
    name: 'Import',
    component: () => import(/* webpackChunkName: "import" */ '../views/import/Import.vue')
  },
  {
    path: '/token',
    name: 'Token',
    component: () => import(/* webpackChunkName: "token" */ '../views/licenza/TokenScaduto.vue')
  },
  {
    path: '/licenza',
    name: 'Licenza',
    component: () => import(/* webpackChunkName: "licenza" */ '../views/licenza/LicenzaScaduta.vue')
  },
  {
    path: '/slide',
    name: 'Slide',
    component: () => import(/* webpackChunkName: "slide" */ '../views/Slide/slide.vue')
  },
  {
    path: '/pdf',
    name: 'Pdf',
    component: () => import(/* webpackChunkName: "pdf" */ '../views/PdfViewer/viewer.vue'),
    props: route => ({ source: route.params.source })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
