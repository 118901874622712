import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    carrello: null
  },
  getters: {
    carrello: state => state.carrello
  },
  mutations: {
    SET_CARRELLO (state, payload) {
      state.carrello = [...payload]
    },
    ADD_RIGA(state, { riga }) {
      state.carrello.push(riga)
    },
    CLEAR_CARRELLO (state) {
      state.carrello = null
    }
  },
  actions: {
  }
}
