import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    movimento: null
  },
  getters: {
    movimento: state => state.movimento
  },
  mutations: {
    SET_MOVIMENTO (state, payload) {
      state.movimento = payload
    },
    CLEAR_MOVIMENTO (state) {
      state.movimento = null
    }
  },
  actions: {
  }
}
