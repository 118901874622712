export default {
  name: 'Name',
  tel: 'Phone',
  save: 'Salva',
  confirm: 'Conferma',
  cancel: 'Annulla',
  delete: 'Cancella',
  complete: 'Completa',
  loading: 'Caricamento...',
  telEmpty: 'Please fill in the tel',
  nameEmpty: 'Please fill in the name',
  nameInvalid: 'Malformed name',
  confirmDelete: 'Sei sicuro di cancellare?',
  telInvalid: 'Malformed phone number',
  vanCalendar: {
    end: 'Fine',
    start: 'Inizio',
    title: 'Calendario',
    startEnd: 'Inizio/Fine',
    weekdays: ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'],
    monthTitle: function monthTitle(year, month) {
      return month + '/' + year
    },
    rangePrompt: function rangePrompt(maxRange) {
      return 'Scegli non più di ' + maxRange + ' giorni'
    }
  },
  vanCascader: {
    select: 'Scegli'
  },
  vanContactCard: {
    addText: 'Add contact info'
  },
  vanContactList: {
    addText: 'Add new contact'
  },
  vanPagination: {
    prev: 'Precedente',
    next: 'Successiva'
  },
  vanPullRefresh: {
    pulling: 'Pull to refresh...',
    loosing: 'Loose to refresh...'
  }
}
