import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    appuntamento: null,
    nuovoAppuntamento: false
  },
  getters: {
    appuntamento: state => state.appuntamento,
    nuovoAppuntamento: state => state.nuovoAppuntamento
  },
  mutations: {
    SET_APPUNTAMENTO (state, { payload, nuovo }) {
      state.appuntamento = { ...payload }
      // Object.assign(state.appuntamento, payload)
      state.nuovoAppuntamento = nuovo
    },
    CLEAR_APPUNTAMENTO (state) {
      state.appuntamento = null
      state.nuovoAppuntamento = false
    }
  },
  actions: {
  }
}
