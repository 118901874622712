import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    cliente: null,
    nuovoCliente: false
  },
  getters: {
    cliente: state => state.cliente,
    nuovoCliente: state => state.nuovoCliente
  },
  mutations: {
    SET_CLIENTE (state, { payload, nuovo }) {
      state.cliente = { ...payload }
      // Object.assign(state.cliente, payload)
      state.nuovoCliente = nuovo
    },
    CLEAR_CLIENTE (state) {
      state.cliente = null
      state.nuovoCliente = false
    }
  },
  actions: {
  }
}
