import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    prodotto: null
  },
  getters: {
    prodotto: state => state.prodotto
  },
  mutations: {
    SET_PRODOTTO (state, payload) {
      state.prodotto = payload
    },
    CLEAR_PRODOTTO (state) {
      state.prodotto = null
    }
  },
  actions: {
  }
}
