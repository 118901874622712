import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import longClickDirective from './directives/longclick'
import * as filters from './filters'
import errorService from '@/services/errorService'
import VCalendar from 'v-calendar'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import VueTheMask from 'vue-the-mask'

// ****** VANT ***********************************
import {
  Locale, Calendar, Cell, CellGroup, Picker, DatetimePicker, Popup, Icon,
  Toast, Dialog, SwipeCell, Button, List, Switch, NumberKeyboard, Field,
  Tag, Tabs, Tab, DropdownMenu, DropdownItem, Form, Sticky, Search, Uploader,
  Collapse, Col, Row, CollapseItem, Stepper, Badge
} from 'vant'
// import itIT from 'vant/es/locale/lang/it-IT'
import itIT from './it-IT'
// Just import this module, then Vant works in PC browser
import '@vant/touch-emulator'

// FONT AWESOME *********************************
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faChild, faCircle, faArchive } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

// Per mantenere leggera la build vanno inserite solo le icone che verranno utilizzate !!!
// Verificare la possibilità di agire sulle icone (senza ulteriore css) per dimensione, flip, etc.
// https://github.com/FortAwesome/vue-fontawesome

// ELENCO ICONE: https://fontawesome.com/icons?d=gallery

library.add(
  faCoffee,
  faChild,
  faCircle,
  faArchive,
  faComment,
  faTwitter
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// ***********************************************
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Calendar)
Vue.use(VueTheMask)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(SwipeCell)
Vue.use(Button)
Vue.use(List)
Vue.use(Switch)
Vue.use(NumberKeyboard)
Vue.use(Field)
Vue.use(Tag)
Vue.use(Tabs).use(Tab)
Vue.use(DropdownMenu).use(DropdownItem)
Vue.use(Form)
Vue.use(Sticky)
Vue.use(Search)
Vue.use(Uploader)
Vue.use(Collapse).use(CollapseItem)
Vue.use(Col).use(Row)
Vue.use(Stepper)
Vue.use(Badge)
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
})
Vue.component('multiselect', Multiselect)
Vue.component('v-date-picker', DatePicker)

Locale.use('it-IT', itIT)
// ***********************************************

Vue.config.productionTip = false

const longClickInstance = longClickDirective({ delay: 400, interval: 0 })
Vue.directive('longclick', longClickInstance)

const repeatClickInstance = longClickDirective({ delay: 400, interval: 50 })
Vue.directive('repeatclick', repeatClickInstance)

for (const key in filters) {
  Vue.filter(key, filters[key])
}

// Handle all Vue errors
Vue.config.errorHandler = (error) => {
  errorService.onError(error)
}
errorService.initHandler()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
